// Hai.js
import React, { useState, useEffect } from 'react';
import TableOfContents from './TableOfContents';
import TextView from './TextView';
import './Whitepaper.css'; // We'll use the same CSS as Whitepaper
import { motion } from 'framer-motion';

const sections = [
    { title: 'HAI Bot: The Ultimate AI Suite for Progressive Evolution', path: 'hai-bot-info', file: 'hai-bot.html' },
    { title: 'Wallet ID Tokenisation: Secure NFT-based Wallet Trading', path: 'tokenization-info', file: 'tokenization.html' },
    { title: 'STY-NFT: Bringing NFTs to Life with Interactive AI', path: 'sty', file: 'sty-info.html' },
    { title: 'Babel: Affordable International Communication Solution', path: 'babel', file: 'babel-info.html' },
    // Add other sections here if necessary
];

const Hai = () => {
    const [selectedSection, setSelectedSection] = useState(sections[0]);
    const [content, setContent] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetch(`/content/${selectedSection.file}`);
            const text = await response.text();
            setContent(text);
        };

        fetchContent();
    }, [selectedSection]);

    const handleSelect = (path) => {
        const section = sections.find(sec => sec.path === path);
        setSelectedSection(section);
        if (isMobile) {
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className="whitepaper"> {/* We're using the whitepaper class for consistency */}
            <TableOfContents sections={sections} onSelect={handleSelect} />
            <TextView title={selectedSection.title} content={content} />
        </div>
    );
};

export default Hai;
// Whitepaper.js
import React, { useState, useEffect } from 'react';
import TableOfContents from './TableOfContents';
import TextView from './TextView';
import './Whitepaper.css';
import { motion } from 'framer-motion';


const sections = [
    { title: 'Introduction to Hyzen AI', path: 'introduction', file: 'introduction.html' },
    { title: 'Hyzen AI Tools', path: 'hai-bot', file: 'hai-bot.html' },
    { title: 'Babel: Breaking Language Barriers', path: 'babel', file: 'babel.html' },
    { title: 'Account ID Tokenization: Secure Wallet ID Trading', path: 'tokenization', file: 'tokenization.html' },
    { title: 'STY-NFT: Revolutionizing NFT Interaction', path: 'sty', file: 'sty.html' },
    { title: 'Tokenomics', path: 'tokenomics', file: 'tokenomics.html' },
    { title: 'NFT Supply Dynamics', path: 'nft-dynamics', file: 'nft-dynamics.html' },
    { title: 'Marketing / Networking', path: 'marketing', file: 'marketing.html' },
    { title: 'Conclusion', path: 'conclusion', file: 'conclusion.html' },

    // Add other sections here
];

const Whitepaper = () => {
    const [selectedSection, setSelectedSection] = useState(sections[0]);
    const [content, setContent] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetch(`/content/${selectedSection.file}`);
            const text = await response.text();
            setContent(text);
        };

        fetchContent();
    }, [selectedSection]);

    const handleSelect = (path) => {
        const section = sections.find(sec => sec.path === path);
        setSelectedSection(section);
        if (isMobile) {
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className="whitepaper">
            <TableOfContents sections={sections} onSelect={handleSelect} />
            <TextView title={selectedSection.title} content={content} />
        </div>
    );
};

export default Whitepaper;
import React, { useEffect, useRef } from 'react';

const IsolatedBackrooms = ({ content, level }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
        if (iframeRef.current) {
            const doc = iframeRef.current.contentDocument;
            doc.open();
            doc.write(`
                <!DOCTYPE html>
                <html>
                    <head>
                        <style>${content.css}</style>
                        <style>
                            .level-indicator {
                                position: fixed;
                                top: 10px;
                                right: 10px;
                                background-color: rgba(0, 0, 0, 0.7);
                                color: #fff;
                                padding: 5px 10px;
                                border-radius: 5px;
                                font-family: monospace;
                            }
                        </style>
                    </head>
                    <body>
                        ${content.html}
                        <div class="level-indicator">Level: ${level}</div>
                        <script>
                            ${content.js}
                        </script>
                    </body>
                </html>
            `);
            doc.close();

            const handleClick = (e) => {
                const levelLink = e.target.closest('a[data-level]');
                if (levelLink) {
                    e.preventDefault();
                    const newLevel = parseInt(levelLink.getAttribute('data-level'));
                    window.parent.postMessage({ type: 'levelChange', level: newLevel }, '*');
                }
            };
            doc.body.addEventListener('click', handleClick);

            return () => {
                doc.body.removeEventListener('click', handleClick);
            };
        }
    }, [content, level]);

    return (
        <iframe
            ref={iframeRef}
            style={{ width: '100%', height: '100vh', border: 'none' }}
            title="Backrooms"
        />
    );
};

export default IsolatedBackrooms;

// src/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="landing-footer">
            <div className="social-links">
                <a href="https://discord.gg/X7X5Gh6MbT" target="_blank" rel="noopener noreferrer" className="social-link">
                    <img src="/images/ecosystem/discord-mark-black.png" alt="Discord" className="social-logo" />
                </a>
                <a href="https://x.com/HyzenAI" target="_blank" rel="noopener noreferrer" className="social-link">
                    <img src="/images/ecosystem/logo-black.png" alt="Twitter" className="social-logo" />
                </a>
            </div>
            <div className="token-info">$HAI 0.0.5364570</div>
        </footer>
    );
};

export default Footer;
// Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './Header.css';
import logo from './green-logo.png'; // Make sure to replace with the correct path to your logo



const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const menuVariants = {
        hidden: { opacity: 0, x: '100%' },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                type: "spring",
                stiffness: 260,
                damping: 20
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: (i) => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: i * 0.1,
                type: 'tween',
                ease: 'easeInOut',
            },
        }),
    };

    const menuItems = [
        { name: 'TEAM', path: '/team' },
        { name: 'WHITEPAPER', path: '/whitepaper' },
        { name: 'ECOSYSTEM', path: '/ecosystem' },
        { name: 'HAI BOT', path: '/hai' },
    ];

    return (
        <header className="header">

            <div className="logo">

                <a href='https://hyzen.ai'>
                    <img src={logo} alt="Logo" className="logo-image" />
                </a>

            </div>
            <div className="hero-content">
                <a href='https://hyzen.ai'><h1 id="siteTitle" >HYZEN AI</h1></a>
            </div>
            <div className="menu-button" onClick={toggleMenu}>
                {menuOpen ? 'X' : '☰'}
            </div>
            <AnimatePresence>
                {menuOpen && (
                    <motion.div
                        className="menu"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={menuVariants}
                        transition={{ duration: 0.5 }}
                    >
                        <ul>
                            {menuItems.map((item, i) => (
                                <motion.li
                                    key={item.name}
                                    custom={i}
                                    variants={itemVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="hidden"
                                    whileHover={{ x: -10 }}
                                >
                                    <Link to={item.path} onClick={toggleMenu}>{item.name}</Link>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </header >
    );
};

export default Header;

// src/TextInput.js
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './TextInput.css';
import axios from 'axios';

const API_URL = "https://leebullish.pythonanywhere.com/generate_text";
const API_KEY = 'abcdef123456'; // Make sure to secure this key properly in a production environment

const systemPrompt = {
    role: 'system',
    content: 'You are the hyzen ai assistant. Hyzen.ai is an ai focused project on hedera. our token is the hai token and its id is 0.0.5364570 it is listed on saucerswap silkswap and c14 which are linked in the ecosystem section of this webiste. the hai tokens utility is being a discounted payment option in all applicable utilities as well as 20% of all profits going to burning hai and 5% of profit going to boosting lp rewards, as well as 5% of profit to our nft holders. our nfts are the hashpimps of which there are 700 with a max supply of 1100 with drop 3 being 50 pimps and drop 4 being 350, and our hashpimp vip passes of which there are 135, the hashpimps and vip passes had 60% of the initial supply airdropped to them of hai, they also have nft staking rewards soon starting with 58 million hai tokens over 3 years dedicated to them and 5% of profits from utilities going to them, the hashpimps were minted in early 2022 for 300 hbars each with 350 pimps then we airdropped a second to each holder to make the current 700, the vip passes were given out for free as a whitelist initially, they now act as a staking boost of 50% for upto 5 pimps paired. our main utilities are hai bot which is currently in demo in our discord server which is an ai aggrregator with many models of varying modalities and a bunch of cool ai features, babel which is an ai transaltion bot for discord servers to alllow multilingual communities, the gaming api/ stynft engine which allows ai npcs to be easily used in games including from nfts, and hedera account id tokenisation allowing the trading of hedera accounts as nfts. all utilities are in public demos in our discord server. our team is lee and jake the co founders and watusi the recent addition and frontend dev/designer. you are a chatbot on our sites landing page to teaCH PEOPLE ABOUT HYZEN, keep messages short usually'
};

function convertToHtml(text) {
    return text
        .replace(/\n\n/g, '</p><p>')
        .replace(/\n/g, '<br>')
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\*(.*?)\*/g, '<em>$1</em>')
        .replace(/^- (.*)/gm, '<li>$1</li>')
        .replace(/<li>.*?<\/li>/gs, match => `<ul>${match}</ul>`);
}

const TextInput = forwardRef(({ onMessageSent, value, onChange }, ref) => {
    const [messageHistory, setMessageHistory] = useState([systemPrompt]);

    useImperativeHandle(ref, () => ({
        submitMessage: (text) => handleSubmit(null, text)
    }));

    const handleSubmit = async (e, overrideText = null) => {
        if (e) e.preventDefault();
        const inputText = overrideText || value;
        if (inputText.trim()) {
            const userMessage = { role: 'user', content: inputText };
            onMessageSent({ text: inputText, sender: 'user' });
            onChange('');

            try {
                const updatedHistory = [...messageHistory, userMessage].slice(-5);
                setMessageHistory(updatedHistory);

                const response = await axios.post(API_URL, {
                    messages: updatedHistory,
                    model: "google/gemma-2-27b-it",
                    max_tokens: 500,
                    temperature: 0.7,
                    top_p: 1.0,
                    frequency_penalty: 0.0,
                    presence_penalty: 0.0
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const botResponse = response.data.generated_text;
                const htmlResponse = convertToHtml(botResponse);
                const assistantMessage = { role: 'assistant', content: htmlResponse };
                setMessageHistory([...updatedHistory, assistantMessage].slice(-5));
                onMessageSent({ text: htmlResponse, sender: 'bot' });
            } catch (error) {
                console.error('Error calling API:', error);
                let errorMessage = 'An unexpected error occurred. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error || errorMessage;
                }
                onMessageSent({
                    text: `Sorry, I encountered an error: ${errorMessage}`,
                    sender: 'bot'
                });
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="text-input-container">
            <input
                type="text"
                placeholder="Say Something..."
                className="text-input"
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
            <button type="submit" className="text-input-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{display: 'block', margin: 'auto'}}>
                    <path d="M4 12L20 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13 5L20 12L13 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </form>
    );
});

export default TextInput;
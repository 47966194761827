// Team.js
import React from 'react';
import { motion } from 'framer-motion';
import './Team.css';
import './LandingPage.css';
import P5Wrapper from './P5Wrapper';
import logo from './green-logo.png'; // Placeholder image for the logo

const teamMembers = [
    {
        name: 'JAKE',
        role: 'Co-founder and Marketing Lead',
        description: `...`,
        imageClass: 'team-member-image-jake'
    },
    {
        name: 'LEE',
        role: 'Co-founder/ Lead Back-End Developer & AI Tool Developer',
        description: `...`,
        imageClass: 'team-member-image-lee'
    },
    {
        name: 'LAW',
        role: 'Branding / Product Designer & Front End Developer',
        description: `...`,
        imageClass: 'team-member-image-law'
    },
];

const Team = () => {
    return (
        <div className="team-container">
            <P5Wrapper />

            <div className="team">
                <div className="team-header">
                    <h1>PASSION x EFFICIENCY x INSANITY</h1>
                </div>
                <div className="team-members">
                    {teamMembers.map((member, index) => (
                        <motion.div
                            className="team-member"
                            key={index}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.2, duration: 0.5 }}
                        >
                            <div className={`team-member-image ${member.imageClass}`}></div>
                            <h2>{member.name}</h2>
                            <h3>{member.role}</h3>
                            <p>{member.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Team;

import React, { useState, useEffect } from 'react';
import './Backrooms.css';
import IsolatedBackrooms from './IsolatedBackrooms';
import { generateBackroomsPiece } from './utils/backroomsUtils';

const Backrooms = () => {
    const [content, setContent] = useState(null);
    const [level, setLevel] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const storedContent = localStorage.getItem('backroomsContent');
        const storedLevel = localStorage.getItem('backroomsLevel');
        if (storedContent && storedLevel) {
            setContent(JSON.parse(storedContent));
            setLevel(parseInt(storedLevel));
            setIsLoading(false);
        } else {
            handleLevelChange(0);
        }

        const handleMessage = (event) => {
            if (event.data.type === 'levelChange') {
                handleLevelChange(event.data.level);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    const handleLevelChange = async (newLevel) => {
        setIsLoading(true);
        setProgress(0);

        try {
            const plan = await generateBackroomsPiece('plan', newLevel);
            setProgress(25);

            const html = await generateBackroomsPiece('html', newLevel, { plan });
            setProgress(50);

            const js = await generateBackroomsPiece('javascript', newLevel, { plan, html });
            setProgress(75);

            const css = await generateBackroomsPiece('css', newLevel, { plan, html, js });
            setProgress(100);

            const newContent = { plan, html, css, js };
            setContent(newContent);
            setLevel(newLevel);
            localStorage.setItem('backroomsContent', JSON.stringify(newContent));
            localStorage.setItem('backroomsLevel', newLevel.toString());
        } catch (error) {
            console.error('Error generating Backrooms level:', error);
            // Handle error (e.g., show error message to user)
        } finally {
            setIsLoading(false);
        }
    };

    if (!content) {
        return <div className="backrooms-loading">Entering the Backrooms...</div>;
    }

    return (
        <div className="backrooms-container">
            {isLoading && (
                <div className="backrooms-loading-overlay">
                    <div className="loading-bar" style={{ width: `${progress}%` }}></div>
                    <div>Generating new level... {progress}%</div>
                </div>
            )}
            <IsolatedBackrooms content={content} level={level} />
        </div>
    );
};

export default Backrooms;
// TextView.js
import React from 'react';
import './TextView.css';

const TextView = ({ title, content }) => {
    return (
        <div className="text-view">
            <div className="text-view-content">
                 {/* Changed from HYZEN WHITEPAPER to HAI BOT */}
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <div className='endOfPage'>
                    <span> End of page </span>
                </div>
            </div>
        </div>
    );
};

export default TextView;
// TableOfContents.js
import React from 'react';
import { motion } from 'framer-motion';
import './TableOfContents.css';

const TableOfContents = ({ sections, onSelect }) => {
    return (
        <motion.div className="table-of-contents"
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}>
            <h4>Contents</h4>
            <ul>
                {sections.map((section, index) => (
                    <li key={index} onClick={() => onSelect(section.path)}>
                        {section.title}
                    </li>
                ))}
            </ul>
        </motion.div>
    );
};

export default TableOfContents;

// src/LandingPage.js
import React, { useState, useEffect, useRef } from 'react';
import P5Wrapper from './P5Wrapper';
import './LandingPage.css';
import TextInput from './TextInput';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import SuggestionCards from './SuggestionCards';

const LandingPage = () => {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(true);
    const navigate = useNavigate();
    const chatMessagesRef = useRef(null);
    const textInputRef = useRef(null);

    const handleNewMessage = (message) => {
        setMessages(prevMessages => [...prevMessages, message]);
        if (message.text.includes("Entering the Backrooms")) {
            setTimeout(() => navigate('/backrooms'), 2000);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion);
        setShowSuggestions(false);
        if (textInputRef.current) {
            textInputRef.current.submitMessage(suggestion);
        }
    };

    useEffect(() => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="landing-page">
            <div className="overlay-top"></div>
            <div className="overlay-bottom"></div>
            <div className="hero-section">
                <P5Wrapper />
                <SuggestionCards 
                    onSuggestionClick={handleSuggestionClick} 
                    isVisible={showSuggestions}
                />
                <div className="chat-messages" ref={chatMessagesRef}>
                    {messages.map((message, index) => (
                        <div key={index} className={`message ${message.sender}`}>
                            <div dangerouslySetInnerHTML={{ __html: message.text }} />
                        </div>
                    ))}
                </div>
            </div>
            <TextInput 
                onMessageSent={handleNewMessage} 
                ref={textInputRef}
                value={inputValue}
                onChange={(value) => {
                    setInputValue(value);
                    setShowSuggestions(false);
                }}
            />
            <Footer />
        </div>
    );
};

export default LandingPage;
// src/Ecosystem.js
import React from 'react';
import './Ecosystem.css';
import P5Wrapper from './P5Wrapper';

const Ecosystem = () => {
    const utilities = [
        { 
            name: 'HAI Bot', 
            logo: '/images/ecosystem/hailogo.png', 
            description: 'Comprehensive AI suite with multiple LLMs, text-to-speech, image generation, and more.'
        },
        { 
            name: 'Babel', 
            logo: '/images/ecosystem/babel.png', 
            description: 'Real-time translation tool for seamless international communication.'
        },
        { 
            name: 'Account ID Tokenisation', 
            logo: '/images/ecosystem/game.png', 
            description: 'Secure trading of Hedera wallet IDs as NFTs.'
        },
        { 
            name: 'Gaming API', 
            logo: '/images/ecosystem/ID.png', 
            description: 'Integrate AI-powered NPCs and interactive elements into games.'
        },
    ];

    const tokenListings = [
        { name: 'SilkSwap DEX', logo: '/images/ecosystem/silkswap_transparent.png', link: 'https://silkdefi.app' },
        { name: 'SaucerSwap DEX', logo: '/images/ecosystem/saucer.png', link: 'https://www.saucerswap.finance/swap/HBAR/0.0.5364570' },
        { name: 'C14', logo: '/images/ecosystem/c14logoo.png', link: 'https://c14.money' },
    ];

    const nftCollections = [
        { 
            name: 'HashPimps', 
            logo: '/images/ecosystem/pimp coin logo.png', 
            tokenId: '0.0.781617',
            marketplace: 'SentX',
            link: 'https://sentx.io/nft-marketplace/hashpimps'
        },
        { 
            name: 'HashPimp V.I.P', 
            logo: '/images/ecosystem/HASHPIMP V.I.P 1.webp', 
            tokenId: '0.0.751194',
            marketplace: 'SentX',
            link: 'https://sentx.io/nft-marketplace/hashpimp-v-i-p-1'
        },
    ];

    return (
        <div className="ecosystem-container">
            <P5Wrapper />
            <div className="ecosystem-content">
                <h1>Hyzen AI Ecosystem</h1>
                
                <section className="utilities-section">
                    <h2>Our Utilities</h2>
                    <div className="utilities-grid">
                        {utilities.map((utility, index) => (
                            <div key={index} className="utility-card">
                                <img src={utility.logo} alt={utility.name} />
                                <h3>{utility.name}</h3>
                                <p>{utility.description}</p>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="listings-section">
                    <h2>$HAI Token Listings</h2>
                    <div className="listings-grid">
                        {tokenListings.map((listing, index) => (
                            <a key={index} href={listing.link} className="listing-card" target="_blank" rel="noopener noreferrer">
                                <img src={listing.logo} alt={listing.name} />
                                <h3>{listing.name}</h3>
                            </a>
                        ))}
                    </div>
                </section>

                <section className="nft-collections-section">
                    <h2>NFT Collections</h2>
                    <div className="nft-collections-grid">
                        {nftCollections.map((collection, index) => (
                            <a key={index} href={collection.link} className="nft-collection-card" target="_blank" rel="noopener noreferrer">
                                <img src={collection.logo} alt={collection.name} />
                                <h3>{collection.name}</h3>
                                <p>Token ID: {collection.tokenId}</p>
                                <p>Marketplace: {collection.marketplace}</p>
                            </a>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Ecosystem;
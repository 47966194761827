// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import Whitepaper from './Whitepaper';
import Team from './Team';
import Header from './Header';
import Hai from './Hai';
import Ecosystem from './Ecosystem';
import Backrooms from './Backrooms'; // Add this line
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/whitepaper" element={<Whitepaper />} />
          <Route path="/team" element={<Team />} />
          <Route path="/hai" element={<Hai />} />
          <Route path="/ecosystem" element={<Ecosystem />} />
          <Route path="/backrooms" element={<Backrooms />} /> {/* Add this line */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
// src/utils/backroomsUtils.js
import axios from 'axios';

const API_BASE_URL = 'http://localhost:5000'; // Adjust if your backend is on a different port

export const generateBackroomsPiece = async (piece, level, previousContent = null) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/backrooms/${piece}`, { 
      level, 
      ...previousContent 
    });

    return response.data[piece];
  } catch (error) {
    console.error(`Error generating Backrooms ${piece}:`, error);
    throw error;
  }
};
// P5Wrapper.js
import React from 'react';
import Sketch from 'react-p5';
import p5 from 'p5';

const P5Wrapper = () => {
    let S, T;
    let f = 30; // Adjusted scaling factor
    let rotate2X = 0;
    let rotate2Z = 0;
    let targetRotate2X = 0;
    let targetRotate2Z = 0;
    const smoothing = 0.05;

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(p5.windowWidth, p5.windowHeight, p5.WEBGL).parent(canvasParentRef).class('p5-canvas');
        p5.ortho(-p5.width / 2, p5.width / 2, -p5.height / 2, p5.height / 2, -2000, 2000);

        // Load table from CSV
        p5.loadTable('edgesManifold.csv', 'csv', 'header', (dataEdges) => {
            const tableArray = dataEdges.getArray();
            tableArray.forEach(row => console.log(row));
        });

        // Shape of data
        const S0 = [
            [0, 0, 0, 0, 1], [1, 0, 0, 0, 1], [1, 1, 0, 0, 1], [0, 1, 0, 0, 1],
            [0, 0, 1, 0, 1], [1, 0, 1, 0, 1], [1, 1, 1, 0, 1], [0, 1, 1, 0, 1],
            [0, 0, 1, 1, 1], [1, 0, 1, 1, 1], [1, 1, 1, 1, 1], [0, 1, 1, 1, 1],
            [0, 0, 0, 1, 1], [1, 0, 0, 1, 1], [1, 1, 0, 1, 1], [0, 1, 0, 1, 1]
        ];

        // Transpose S0
        S = S0[0].map((_, colIndex) => S0.map(row => row[colIndex] - 0.5));

        // Edges
        const A = [
            [0, 1], [1, 2], [2, 3], [3, 0], [3, 7], [0, 4], [1, 5], [2, 6], [5, 6], [4, 5],
            [6, 7], [4, 7], [0, 12], [8, 12], [4, 8], [12, 13], [13, 14], [3, 15], [9, 13],
            [9, 10], [10, 14], [12, 15], [14, 15], [11, 15], [8, 11], [10, 11], [8, 9],
            [5, 9], [7, 11], [6, 10], [1, 13], [2, 14]
        ];

        const matmul = (a, b) => {
            const rowsA = a.length, colsA = a[0].length;
            const rowsB = b.length, colsB = b[0].length;
            const result = Array(rowsA).fill().map(() => Array(colsB).fill(0));
            for (let i = 0; i < rowsA; i++) {
                for (let j = 0; j < colsB; j++) {
                    for (let k = 0; k < colsA; k++) {
                        result[i][j] += a[i][k] * b[k][j];
                    }
                }
            }
            return result;
        };

        const tourne = (p5) => {
            let t = p5.PI;
            let u = p5.PI;
            let v = targetRotate2Z;
            let w = rotate2X;
            let z = targetRotate2X;
            let x = rotate2Z;

            let Rxw, Rxy, Rxz, Ryw, Ryz, Rzw;

            Rxw = [
                [1, 0, 0, 0, 0],
                [0, Math.cos(t), -Math.sin(t), 0, 0],
                [0, Math.sin(t), Math.cos(t), 0, 0],
                [0, 0, 0, 1, 0],
                [0, 0, 0, 0, 1]
            ];
            Rxy = [
                [1, 0, 0, 0, 0],
                [0, 1, 0, 0, 0],
                [0, 0, Math.cos(w), -Math.sin(w), 0],
                [0, 0, Math.sin(w), Math.cos(w), 0],
                [0, 0, 0, 0, 1]
            ];
            Rxz = [
                [1, 0, 0, 0, 0],
                [0, Math.cos(z), 0, -Math.sin(z), 0],
                [0, 0, 1, 0, 0],
                [0, Math.sin(z), 0, Math.cos(z), 0],
                [0, 0, 0, 0, 1]
            ];
            Ryw = [
                [Math.cos(u), 0, -Math.sin(u), 0, 0],
                [0, 1, 0, 0, 0],
                [Math.sin(u), 0, Math.cos(u), 0, 0],
                [0, 0, 0, 1, 0],
                [0, 0, 0, 0, 1]
            ];
            Ryz = [
                [Math.cos(x), 0, -Math.sin(x), 0, 0],
                [0, 1, 0, 0, 0],
                [0, 0, 1, 0, 0],
                [Math.sin(x), 0, 0, Math.cos(x), 0],
                [0, 0, 0, 0, 1]
            ];
            Rzw = [
                [Math.cos(v), -Math.sin(v), 0, 0, 0],
                [Math.sin(v), Math.cos(v), 0, 0, 0],
                [0, 0, 1, 0, 0],
                [0, 0, 0, 1, 0],
                [0, 0, 0, 0, 1]
            ];

            T = matmul(matmul(matmul(matmul(matmul(matmul(Rzw, Ryz), Ryw), Rxz), Rxy), Rxw), S);
            const P = [
                [1, 0, 0, 0],
                [0, 1, 0, 0],
                [0, 0, 1, 0],
                [0, 0, 0, 1]
            ];
            T = matmul(P, T);
        };

        p5.draw = () => {
            p5.background('#212124');
            // p5.translate(0, 0, 0);
            p5.translate(0, p5.height * 0.1, 0);  // Move down by 10% of the canvas height

            rotate2X += 0.001;
            rotate2Z += 0.001;

            targetRotate2X += (p5.map(p5.mouseY, 0, p5.height, -p5.PI, p5.PI) - targetRotate2X) * smoothing;
            targetRotate2Z += (p5.map(p5.mouseX, 0, p5.width, 0, 2 * p5.PI) - targetRotate2Z) * smoothing;

            console.log(`rotate2X: ${rotate2X}, rotate2Z: ${rotate2Z}`);

            tourne(p5);

            for (let i = 0; i < A.length; i++) {
                const [x0, y0, z0] = [T[0][A[i][0]], T[1][A[i][0]], T[2][A[i][0]]];
                const [x1, y1, z1] = [T[0][A[i][1]], T[1][A[i][1]], T[2][A[i][1]]];
                p5.stroke(158, 255, 0, 150);
                p5.line(f * x0, f * y0, f * z0, f * x1, f * y1, f * z1);
            }
        };

        p5.windowResized = () => {
            p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
            p5.ortho(-p5.width / 2, p5.width / 2, -p5.height / 2, p5.height / 2, -2000, 2000);
        };
    };

    return <Sketch setup={setup} draw={p5.draw} windowResized={p5.windowResized} />;
};

export default P5Wrapper;
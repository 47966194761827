import React from 'react';
import { motion } from 'framer-motion';
import './SuggestionCards.css';

const SuggestionCards = ({ onSuggestionClick, isVisible }) => {
    const suggestions = [
        "Tell me about Hyzen AI",
        "Tell me about HAI token",
        "Help me make a shopping list.",
        "Tell me a joke.",
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1,
            },
        },
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
            },
        },
    };

    if (!isVisible) return null;

    return (
        <motion.div
            className="suggestion-container"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <motion.div className="logo-container" variants={cardVariants}>
                <img src="/hai-logo.svg" alt="HAI Logo" className="hai-logo" />
            </motion.div>
            <motion.div className="welcome-header" variants={cardVariants}>
                <h2>Welcome to Hyzen AI</h2>
                <p>Type a message or choose a suggestion below to get started</p>
            </motion.div>
            <div className="suggestion-cards">
                {suggestions.map((suggestion, index) => (
                    <motion.div
                        key={index}
                        className="card"
                        onClick={() => onSuggestionClick(suggestion)}
                        variants={cardVariants}
                        whileHover={{
                            y: -5,
                            boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)",
                            transition: { duration: 0.3 },
                        }}
                    >
                        <h3></h3>
                        <p>{suggestion}</p>
                    </motion.div>
                ))}
            </div>
        </motion.div>
    );
};

export default SuggestionCards;